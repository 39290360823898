/**
 * HelmetHead component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import HKGroteskProRegular2 from "../assets/fonts/HK Grotesk Pro/HKGroteskPro-Regular.woff2"
import HKGroteskProMedium2 from "../assets/fonts/HK Grotesk Pro/HKGroteskPro-Medium.woff2"

function HelmetHead({
  description,
  lang,
  metas,
  links,
  title,
  keywords,
  img,
  children,
}) {
  const { site, allImageSharp } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        allImageSharp(
          filter: {
            fluid: { originalName: { eq: "Maklerzentrale-LupeWeiss.jpg" } }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 512) {
                src
              }
            }
          }
        }
      }
    `
  )

  const location = useLocation()
  const pathname = location.pathname

  const defaultLangPathName = pathname //.replace(`/de/`, `/de/`)

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaImg = img || `${process.env.GATSBY_SITE_URL}${allImageSharp.edges[0].node.fluid.src}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s`}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "viewport",
          content: "width=device-width",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        ...[
          keywords
            ? {
                property: `keywords`,
                content: keywords,
              }
            : {},
        ],
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImg,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImg,
        },
      ].concat(metas)}
      link={[
        ...[
          links && links.map(l => l.rel === "canoncial").length
            ? {}
            : {
                //default
                rel: "canonical",
                href: `${process.env.GATSBY_SITE_URL}${defaultLangPathName}`,
              },
        ],
        {
          rel: "preload",
          as: "font",
          href: HKGroteskProRegular2,
          type: "font/woff2",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          as: "font",
          href: HKGroteskProMedium2,
          type: "font/woff2",
          crossOrigin: "anonymous",
        },
      ].concat(links)}
    >
      {children}
    </Helmet>
  )
}

HelmetHead.defaultProps = {
  lang: `de`,
  metas: [],
  links: [],
  description: ``,
}

HelmetHead.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  metas: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default HelmetHead
