import React, { useContext, useEffect } from "react"
import HelmetHead from "../../components/HelmetHead"
import LayoutContext from "../../components/LayoutContext"

const Impressum = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        fixed: false,
        bgColor: "white",
        color: "light",
      },
    }))

    //todo: window.cookiehub.closeDialog()
  }, [])
  return (
    <>
      <HelmetHead
        title="Impressum Maklerzentrale"
        metas={[{ name: "robots", content: "noindex" }]}
      />
      <section className="bg-light pt-8 pt-md-11 pb-8">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h1 className="display-4 mb-2">Impressum</h1>
            </div>
            <div className="col-auto">
              <p className="fs-lg text-gray-700 mb-md-0">Stand: 7. Juli 2020</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 mb-5">
              <h2 className="mb-0">Maklerzentrale</h2>
              <p className="fs-lg text-gray-800">
                Anzengruberstr. 13<br />
                12043 Berlin
              </p>
              <p className="text-muted mb-6 mb-md-8">
                Gesellschafter:
                <br />
                Albino Salvatore Cipolla, M.A.
                <br />
                Tobias Brauchle, B.Eng.
                <br />
                <br />
                USt-IdNr.: DE341471761
                <br />
                <br />
                Verantwortliche für journalistisch-redaktionelle Inhalte gem. §
                55 II RstV: Albino Cipolla, Tobias Brauchle
                <br />
                <br />
                Plattform der Europäischen Kommission zur Online-Streitbeilegung
                (OS): ec.europa.eu/consumers/odr
              </p>
            </div>
            <div className="col-12 col-md-4 mb-5">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Kontakt</h4>
                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Telefon
                  </h6>
                  <p className="fs-sm mb-5">
                    <a href="tel:+498002242020" className="text-reset">
                      0800 2242020
                    </a>
                  </p>
                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    E-Mail
                  </h6>
                  <p className="fs-sm mb-0">
                    <a
                      href="mailto:info@maklerzentrale.com"
                      className="text-reset"
                    >
                      info@maklerzentrale.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <h3 className="mb-5">Haftung für Inhalte</h3>
              <p className="text-muted mb-6 mb-md-8">
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen.
                <br />
                <br />
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                wir diese Inhalte umgehend entfernen.
              </p>
              <h3 className="mb-5">Haftung für Links</h3>
              <p className="text-muted mb-6 mb-md-8">
                Unser Angebot enthält Links zu externen Websites Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar.
                <br />
                <br />
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
              </p>
              <h3 className="mb-5">Urheberrecht</h3>
              <p className="text-muted">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet.
                <br />
                <br />
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.
                <br />
                <br />
                Alle hier verwendeten Namen, Begriffe, Zeichen und Grafiken
                können Marken- oder Warenzeichen im Besitze ihrer rechtlichen
                Eigentümer sein. Die Rechte aller erwähnten und benutzten
                Marken- und Warenzeichen liegen ausschließlich bei deren
                Besitzern.
              </p>
              <p>Widerspruch gegen Datenerfassung</p>
              <p className="text-muted mb-6 mb-md-8">
                Sie können die Erfassung Ihrer Daten durch Google Analytics
                verhindern, indem Sie auf folgenden Link klicken. Es wird ein
                Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
                zukünftigen Besuchen dieser Website verhindert: Google Analytics
                deaktivieren.
              </p>
              <h3 className="mb-5">Bildquellen</h3>
              <p className="text-muted">
                {/* Index */}
                {/* Immo verkaufen */}
                {/* Immobewertung */}
                {/* Immomakler DE */}
                {/* Immomakler AT */}
                {/* Immomakler CH */}
                {/* Kontakt */}
                {/* Karriere */}
                {/* Über Uns */}
                <br />© Tiberius Gracchus – Fotolia.com ID: #95765983
                <br />© Tiberius Gracchus – Fotolia.com ID: #187315117
                <br />© ty #269827206
                <br />© hpunkt_de #42187343
                <br />© hpunkt_de #42187344
                <br />© sachi_yn #187278315
                <br />© elnariz #209278828
                <br />© eric #179308448
                <br />© petovarga #130713144
                <br />© contrastwerkstatt #55767668
                <br />© eyetronic #204477295
                <br />© Alexander Raths #102603660
                <br />© goodluz #172382369
                <br />© goodluz #91623898
                <br />© goodluz #58883678
                <br />© hpunkt_de #41922087
                <br />© Krakenimages.com #288770328
                <br />© Chlorophylle #332883849
                <br />© petovarga #118240947
                <br />© treenabeena #28840421
                <br />© Audrey Design #199095478
                <br />© Photo by deborah cortelazzi on Unsplash
                <br />© free-photo-jolps on pxfuel.com
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Impressum
